/* eslint-disable import/no-anonymous-default-export */
const tad = {
  authorities: {
    signUpSignIn: {
      authority: "https://clpmrcb2c.b2clogin.com/clpmrcb2c.onmicrosoft.com/B2C_1_CustomerPortalSiUpIn",
    },
    editProfile: {
      authority: "https://clpmrcb2c.b2clogin.com/clpmrcb2c.onmicrosoft.com/B2C_1_CustomerPortalEditProfile"
    }
  },
  authorityDomain: "clpmrcb2c.b2clogin.com"
}

const prod = {
  authorities: {
    signUpSignIn: {
      authority: "https://cldmrcb2c.b2clogin.com/cldmrcb2c.onmicrosoft.com/B2C_1_CustomerPortalSiUpIn",
    },
    editProfile: {
      authority: "https://cldmrcb2c.b2clogin.com/cldmrcb2c.onmicrosoft.com/B2C_1_CustomerPortalEditProfile"
    }
  },
  authorityDomain: "cldmrcb2c.b2clogin.com"
}

const config = process.env.REACT_APP_ENV === "PROD" ? prod : tad;

export default {
  ...config
};