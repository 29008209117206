import classes from "./NavbarMenuLinks.module.css"

function HeaderLinksList(props) {
    return (
        <ul className={classes.menu}>
            {props.children}
        </ul>
    );
}

function HeaderLink(props) {
    return (
        <li>
            <a id={props.id} className={classes.menuItem} href={props.link} target={props.target} rel="noreferrer">{props.text}</a>
        </li>
    );
}

function HeaderButton(props) {
    return (
        <li>
            <input type="button" id={props.id} className={classes.menuButton} onClick={props.action} value={props.text} />
        </li>
    );
}

export { HeaderLinksList, HeaderLink, HeaderButton };