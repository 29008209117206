import { useMsal } from "@azure/msal-react";

import { HeaderLinksList, HeaderLink, HeaderButton } from './NavbarMenuLinks';

import classes from './NavbarMenuBar.module.css'
import logo from '../../images/mrc-logo-colour.png'

function NavbarMenuBar(props) {
    const { instance } = useMsal();
    const isAuthenticated = props.authenticated;

    function HandleLogout() {
        sessionStorage.removeItem('loginWithSSO');
        instance.logoutRedirect();
    }

    function HandleLogin() {
        sessionStorage.setItem('loginWithSSO', true);
        instance.loginRedirect();
    }

    return (
        <div className={classes.menu_bar}>
            <div className={"navbar container " + classes.mainHeaderStyle}>
                <div className={"nav " + classes.navbarLogoContainer}>
                    <img className={classes.navbarLogo} src={logo} alt="MRC Logo" />
                </div>
                <div className={"nav show " + classes.floatRight + ' ' + classes.navbarText}>
                    <HeaderLinksList>
                        <HeaderLink id="councilWebsite" text="COUNCIL'S WEBSITE" link="http://www.mackay.qld.gov.au/" target="_blank" />
                        {isAuthenticated && <HeaderButton id="signOut" text="Sign Out" action={() => HandleLogout()} />}
                        {!isAuthenticated && <HeaderButton id="signIn" text="Sign In" action={() => HandleLogin()} />}
                    </HeaderLinksList>
                </div>
            </div>
        </div>
    );
}

export default NavbarMenuBar;