/* eslint-disable import/no-anonymous-default-export */
import b2cPolicies from "./msalPolicies"
import { LogLevel } from "@azure/msal-browser";

const tad = {
    auth: {
        clientId: "ed5b5262-5a37-40d8-bb73-d5544b0551ea",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

const prod = {
    auth: {
        clientId: "c000736a-fda0-4a9e-8504-5f751d8abd82",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

const config = process.env.REACT_APP_ENV === "PROD" ? prod : tad;

export default {
    ...config
};