import classes from './Footer.module.css'
import FooterContainer from './FooterContainer'

function Footer(props) {
    return (
        <footer className={classes.footerArea}>
            <FooterContainer {...props} />
        </footer>
    );
}

export default Footer;