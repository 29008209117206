import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';

import classes from './Body.module.css';
import NewsContainer from './NewsContainer';
import TileGrid from "./TileGrid";
import ListRenew from './ListRenew';
import ListPayments from './ListPayments';
import BodyDivider from './BodyDivider';

import Title from './Title';
import TitleButton from './TitleButton';

class Body extends Component {
  render() {
    return (
      <main className={"container " + classes.body}>
        <div>
          <div>
            <Routes>
              <Route path='/' element={<Title />} />
              <Route path='/*' element={<TitleButton />} />
            </Routes>
          </div>
          <Routes>
            <Route path='/' element={<TileGrid />} />
            <Route path='/RenewYourLicence' element={<ListRenew />} />
            <Route path='/MakeAPayment' element={<ListPayments />} />
          </Routes>
        </div>

        <BodyDivider />

        <div>
          <div>
            <h2 className={"mt-5 " + classes.headerText}>Latest News</h2>
          </div>

          <NewsContainer />
        </div>
      </main>
    );
  }
}

export default Body;