import classes from './TileGrid.module.css'
import Tile from "./Tile";

// ImageImports
import makeAPayemnt from '../../images/make-a-payment.jpg'
import lodgeMyCustomerRequest from '../../images/lodge-my-request.jpg'
import viewMyRatesAccount from '../../images/view-my-rates.jpg'
import viewMyNotices from '../../images/view-my-notices.jpg'
import viewMyWaterUse from '../../images/view-my-water-use.jpg'
import councilVacancies from '../../images/council-vacancies.jpg'
import renewMyLicence from '../../images/renew-my-licence.jpg'
import lodgeAnApplication from '../../images/lodge-an-application.jpg'
import newLicencesOrPermit from '../../images/new-licences-or-permit.jpg'
import searchesAndCertificates from '../../images/searches-and-certificates.jpg'
import eapplicationTracker from '../../images/eapplication-tracker.jpg'
import accessMimaps from '../../images/access-mimaps.jpg'
import registerMyAnimal from '../../images/register-my-animal.jpg'
import findMyLostAnimal from '../../images/find-my-lost-animal.jpg'
import accessLibraryServices from '../../images/access-library-services.jpg'
import freeNativePlantProgram from '../../images/free-native-plant-program.jpg'
import makeABooking from '../../images/make-a-booking.jpg'
import burialRegister from '../../images/burial-register.jpg'

function TileGrid(props) {

    const myh2oLinkWithAuth = "https://myh2o.qld.gov.au/signUpSSO"
    const myh2oLinkWithoutAuth = "https://myh2o.qld.gov.au/"
    const libraryLinkWithAuth = "https://library.mackay.qld.gov.au/montage/forcesso.aspx"
    const libraryLinkWithoutAuth = "https://library.mackay.qld.gov.au/montage/"

    const myh20Link = props.authenticated ? myh2oLinkWithAuth : myh2oLinkWithoutAuth;
    const libraryLink = props.authenticated ? libraryLinkWithAuth : libraryLinkWithoutAuth;

    return (
        <div className={classes.tileGrid}>
            <Tile id="makeAPayemnt" img={makeAPayemnt} link="/MakeAPayment" target="_self" />
            <Tile id="lodgeMyCustomerRequest" img={lodgeMyCustomerRequest} link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/web/Mobility/CityWatch/Index.html?Module=ECRREQT" target="_blank" />
            <Tile id="viewMyRatesAccount" img={viewMyRatesAccount} authRequired link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/GeneralEnquiry/ExternalRequestBroker.aspx?module=egelra&class=&type=LRA&forcelogin=autodetect" target="_blank" />
            <Tile id="viewMyNotices" img={viewMyNotices} authRequired link="https://mackay.enotices.com.au/" target="_blank" />
            <Tile id="viewMyWaterUse" img={viewMyWaterUse} authRequired link={myh20Link} target="_blank" />
            <Tile id="councilVacancies" img={councilVacancies} authRequired link="https://vacancies.mackay.qld.gov.au/MRC_ER/VacancyList.aspx" target="_blank" />
            <Tile id="renewMyLicence" img={renewMyLicence} link="/RenewYourLicence" target="_self" />
            <Tile id="lodgeAnApplication" img={lodgeAnApplication} link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Applications/NewApplication.aspx" target="_blank" />
            <Tile id="newLicencesOrPermit" img={newLicencesOrPermit} link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Licensing/NewLicence.aspx" target="_blank" />
            <Tile id="searchesAndCertificates" img={searchesAndCertificates} authRequired link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Certificates/NewCertificate.aspx" target="_blank" />
            <Tile id="eapplicationTracker" img={eapplicationTracker} link="https://planning.mackay.qld.gov.au/Pages/XC.Track/SearchApplication.aspx" target="_blank" />
            <Tile id="accessMimaps" img={accessMimaps} link="https://mimaps.mackay.qld.gov.au/" target="_blank" />
            <Tile id="registerMyAnimal" img={registerMyAnimal} link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Animals/NewAnimal.aspx" target="_blank" />
            <Tile id="findMyLostAnimal" img={findMyLostAnimal} link="https://animals.mackay.qld.gov.au/" target="_blank" />
            <Tile id="accessLibraryServices" img={accessLibraryServices} authRequired link={libraryLink} target="_blank" />
            <Tile id="freeNativePlantProgram" img={freeNativePlantProgram} authRequired link="https://nativeplants.mackay.qld.gov.au/" target="_blank" />
            <Tile id="makeABooking" img={makeABooking} link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/Citywatch/Index.html?Module=EBKBOOK" target="_blank" />
            <Tile id="burialRegister" img={burialRegister} link="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web//GeneralEnquiry/ExternalRequestBroker.aspx?Module=EGECRG&Class=CEMT&Type=CEMT" target="_blank" />
        </div>
    );
}

export default TileGrid;