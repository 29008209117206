import classes from './FooterContainer.module.css'
import LinksContainer from './LinksContainer';
import SocialMediaContainer from './SocialMediaContainer';

function FooterContainer(props) {
    return (
        <div className={"row container " + classes.footerContainer}>
            <LinksContainer {...props} />
            <SocialMediaContainer />
        </div>
    );
}

export default FooterContainer;