import classes from './ListStyling.module.css'

function ListPayments() {
    return (
        <div className={classes.container}>
            <ul className={classes.list}>
                <li className={classes.item}><a id="accountPayment" href="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/CityWatch/index.html?Module=ERCPAYM" target="_blank" rel="noreferrer">Accounts Receivable Payment</a></li>
                <li className={classes.item}><a id="animalPayment" href="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/CityWatch/index.html?Module=ERCPAYM" target="_blank" rel="noreferrer">Animal Registration Renewal</a></li>
                <li className={classes.item}><a id="applicationPayment" href="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/CityWatch/index.html?Module=ERCPAYM" target="_blank" rel="noreferrer">Application Payment</a></li>
                <li className={classes.item}><a id="infringementPayment" href="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/CityWatch/index.html?Module=ERCPAYM" target="_blank" rel="noreferrer">Parking, Animal, Building & Development, Water & Other Infringement</a></li>
                <li className={classes.item}><a id="billPayment" href="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/CityWatch/index.html?Module=ERCPAYM" target="_blank" rel="noreferrer">Rates, Water Bills and Trade Waste Discharge Fees</a></li>
                <li className={classes.item}><a id="licencePayment" href="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/CityWatch/index.html?Module=ERCPAYM" target="_blank" rel="noreferrer">Trade Waste Licence, Animal Licence, Backflow Licence, Commercial Vehicle Licence</a></li>
            </ul>

        </div>
    );
}

export default ListPayments;