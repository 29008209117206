import classes from './TitleButton.module.css'
import { Link } from 'react-router-dom';

function TitleButton() {
    return (
        <div>
            <div>
                <h2 className={classes.titleText}>Online Services</h2>
                <Link className={classes.linkStyle} to="/">
                    Back
                </Link>
            </div>
        </div>
    );
}

export default TitleButton;