import { useState, useEffect } from 'react'
import classes from './NewsContainer.module.css'
import chevronRight from '../../images/chevron-right.png'

function NewsListContainer() {

    const [items, setItems] = useState();

    useEffect(() => {
        fetch('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.mackay.qld.gov.au%2Fabout_council%2Fnews_and_media%2Fmedia_releases%2Frss')
            .then(res => res.json())
            .then(data => setItems(data.items.slice(0, 4)));
    }, []);

    return (
        <>
            {items && <NewsList items={items} />}
        </>
    );
}

function NewsList(props) {
    return (
        <div className={classes.newsListContainer}>

            {props.items.map(item => (
                <NewsItem key={item.guid} text={item.title} href={item.link} />
            ))}

        </div>
    );
}

function NewsItem(props) {
    const trimLength = 50;
    var text = props.text;

    if (text.length > trimLength) {
        text = text.slice(0, trimLength) + "..."
    }

    return (
        <a className={classes.newsItemLink}
            href={props.href}
            target="_blank"
            rel="noreferrer">

            <div id="openNews" className={classes.newsItemContainer}>

                <img className={classes.chevronRight} src={chevronRight} alt="Chevron" />

                <div className={classes.newsItemText}>
                    <p>{text}</p>
                </div>

            </div>

        </a>
    );
}

export default NewsListContainer;