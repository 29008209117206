import classes from './LoadingLogin.module.css'

function LoadingLogin() {
    return (
        <div className={"container " + classes.container} role="presentation">
            <div className={"row " + classes.row}>
                <div className={classes.panel}>
                    <div className={"row " + classes.panelContainer}>
                        <div className={classes.loader}></div>
                        <h5 className={classes.headerText}>Redirecting to login!</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingLogin;