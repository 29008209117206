import classes from './SocialMediaContainer.module.css'
import stayConnected from '../../images/stay-connected.png'
import facebookIcon from '../../images/facebook-icon.png'
import instagramIcon from '../../images/instagram-icon.png'
import linkedInIcon from '../../images/linkedIn-icon.png'
import pinterestIcon from '../../images/pinterest-icon.png'
import twitterIcon from '../../images/twitter-icon.png'
import youTubeIcon from '../../images/youTube-icon.png'



function SocialMediaContainer() {
    return (
        <div className={"col-lg-6 col-md-12 col-sm-12 col-12 " + classes.socialLinksContainer}>
            <div className={classes.socialLinksArea}>

                <img className={classes.footerStayConnected} src={stayConnected} alt="MRC Connected" />

                <div>
                    <SocialMediaLink id="openPinterest" href="https://www.pinterest.com.au/mackayregionalcouncil/" src={pinterestIcon} alt="Pinterest Icon" />
                    <SocialMediaLink id="openInstagram" href="https://www.instagram.com/mackaycouncil/" src={instagramIcon} alt="Instagram Icon" />
                    <SocialMediaLink id="openYoutube" href="https://www.youtube.com/user/counciltv" src={youTubeIcon} alt="Youtube Icon" />
                    <SocialMediaLink id="openLinkedIn" href="https://www.linkedin.com/company/mackayregionalcouncil?trk=NUS_CMPY_TWIT" src={linkedInIcon} alt="LinkedIn Icon" />
                    <SocialMediaLink id="openTwitter" href="https://twitter.com/mackaycouncil" src={twitterIcon} alt="Twitter Icon" />
                    <SocialMediaLink id="openFacebook" href="https://www.facebook.com/mackayregionalcouncil" src={facebookIcon} alt="Facebook Icon" />
                </div>

            </div>
        </div>
    );
}

function SocialMediaLink(props) {
    return (
        <div className={classes.socialMediaLink}>
            <a href={props.href} target="_blank" rel="noreferrer">
                <img id={props.id} src={props.src} alt={props.alt} />
            </a>
        </div>
    );
}

export default SocialMediaContainer;