import classes from "./FooterLinksList.module.css"

function FooterLinksList(props) {
    return (
        <ul className={classes.footerLinks}>
            <li className={classes.linkHeader}>{props.title}</li>

            {props.children}

        </ul>
    );
}

function FooterLink(props) {
    return (
        <li>
            <a id={props.id} className={classes.link} href={props.link} target={props.target} rel="noreferrer">{props.text}</a>
        </li>
    );
}

export { FooterLinksList, FooterLink };