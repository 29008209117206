import classes from './ListStyling.module.css'

function ListRenew() {
    return (
        <div className={classes.container}>
            <ul className={classes.list}>
                <li className={classes.item}><a id="renewAdditionalAnimalLicence" href="https://www.mackay.qld.gov.au/residents/local_laws/forms/application_for_a_renewal_of_an_additional_animal_approval" target="_blank" rel="noreferrer">Renew your Additional Animal Licence</a></li>
                <li className={classes.item}><a id="renewAccommodationApproval" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_of_rental_accommodation" target="_blank" rel="noreferrer">Renew your Accommodation (Hotel, BNB and Hostel) Approval</a></li>
                <li className={classes.item}><a id="renewCaravanParkApproval" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_of_caravan_park_permit" target="_blank" rel="noreferrer">Renew your Caravan Park Approval</a></li>
                <li className={classes.item}><a id="renewCommercialVehicleLicence" href="https://onlineservices.mackay.qld.gov.au/ePathway/MRCEPATHWEB11/Web/Mobility/CityWatch/index.html?Action=CREATE&Module=ERCPAYM&Class=LC" target="_blank" rel="noreferrer">Renew your Commercial Vehicle Licence</a></li>
                <li className={classes.item}><a id="renewFoodBusinessLicence" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_food_business_licence" target="_blank" rel="noreferrer">Renew your Food Business Licence</a></li>
                <li className={classes.item}><a id="renewFootpathDinningApproval" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_of_footpath_dining_permit" target="_blank" rel="noreferrer">Renew your Footpath Dining Approval</a></li>
                <li className={classes.item}><a id="renewItinerantVendingApproval" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_of_itinerant_permit" target="_blank" rel="noreferrer">Renew your Itinerant Vending Approval</a></li>
                <li className={classes.item}><a id="renewKennelPermit" href="https://www.mackay.qld.gov.au/residents/local_laws/forms/application_for_renewal_of_kennelcattery" target="_blank" rel="noreferrer">Renew your Kennel Permit</a></li>
                <li className={classes.item}><a id="renewSkinPenetrationLicence" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_of_registration_of_premises_for_skin_penetration" target="_blank" rel="noreferrer">Renew your Licence of Premises for Skin Penetration</a></li>
                <li className={classes.item}><a id="renewPetShopLicence" href="https://www.mackay.qld.gov.au/residents/local_laws/forms/application_for_renewal_of_a_pet_shop_approval" target="_blank" rel="noreferrer">Renew your Pet Shop Licence</a></li>
                <li className={classes.item}><a id="renewStablePermit" href="https://www.mackay.qld.gov.au/residents/local_laws/forms/application_for_renewal_of_stable_approval" target="_blank" rel="noreferrer">Renew your Stable Permit</a></li>
                <li className={classes.item}><a id="renewStaticVendingApproval" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_of_static_vending_permit" target="_blank" rel="noreferrer">Renew your Static Vending Approval</a></li>
                <li className={classes.item}><a id="renewSwimmingPoolApproval" href="https://www.mackay.qld.gov.au/business/permits_and_licenses/online_licence_forms/application_for_renewal_of_swimming_pool_permit" target="_blank" rel="noreferrer">Renew your Swimming Pool Approval</a></li>
            </ul>

        </div>
    );
}

export default ListRenew;