import NavbarMenuBar from './NavbarMenuBar';
import classes from './Header.module.css'

function Header(props) {
    return (
        <header className={classes.header}>
            <NavbarMenuBar {...props} />
        </header>
    );
}

export default Header;