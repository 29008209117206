import { FooterLinksList, FooterLink } from './FooterLinksList';

function LinksContainer() {
    return (
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <FooterLinksList title="Quick Links">
                <FooterLink id="contactCouncil" text="Contact Council" link="http://www.mackay.qld.gov.au/contact" target="_blank" />
                <FooterLink id="privacyDisclaimer" text="Privacy/Disclaimer" link="https://www.mackay.qld.gov.au/privacydisclaimer" target="_blank" />
            </FooterLinksList>
        </div>
    );
}

export default LinksContainer;