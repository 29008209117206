import classes from './Tile.module.css'

import { FaLock } from 'react-icons/fa'

function Tile(props) {

    const getDeviceIcon = () => props.authRequired ? <span title="Sign In Required"><FaLock className={classes.overlayIcon} /></span> : null;

    return (
        <div className={classes.overlayContainer}>
            <a href={props.link} target={props.target}>
                <img id={props.id} src={props.img} className={classes.tileImage} alt="Tile" />
                <div className={classes.overlay}>
                    {getDeviceIcon()}
                </div>
            </a>
        </div>
    );
}

export default Tile;