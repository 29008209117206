import classes from './BodyDivider.module.css'

function BodyDivider() {
    return (
        <div className={classes.bodyDivider}>

        </div>
    );
}

export default BodyDivider;