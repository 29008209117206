import classes from './LoginOptions.module.css';
import logo from '../../images/mrc-logo-colour.png';
import PropTypes from 'prop-types';

function LoginOptions(props) {
  const { SetLoginOption } = props;

  return (
    <main className={`container ${classes.body}`}>
      <div className={classes.loginOption}>
        <img className={classes.logonOptionImg} src={logo} alt="Logo" />

        <p className={classes.logonOptionText}>
          We recommend signing in to improve your experience with council's online services.
        </p>

        <button
          id="signInWithAccount"
          className={`${classes.btn} ${classes.btnSocial}`}
          onClick={() => SetLoginOption('useSSO')}
        >
          Sign In with Account
        </button>

        <div className={classes.seperator}>
          <div className={`${classes.seperatorLine} ${classes.seperatorLeft}`}></div>
          <p className={classes.seperatorText}>OR</p>
          <div className={`${classes.seperatorLine} ${classes.seperatorRight}`}></div>
        </div>

        <button
          id="signInLater"
          className={`${classes.btn} ${classes.btnLater}`}
          onClick={() => SetLoginOption('skip')}
        >
          Sign in Later
        </button>
      </div>
    </main>
  );
}

LoginOptions.propTypes = {
  SetLoginOption: PropTypes.func.isRequired,
};

export default LoginOptions;