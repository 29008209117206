import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

import { useState } from 'react';
import React from 'react';
import { useMsal } from "@azure/msal-react";

import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Body from './Components/Body/Body';
import LoginOptions from './Components/LoginOptions/LoginOptions';
import LoadingLogin from './Components/LoadingLogin/LoadingLogin';

import background from './images/background.jpg'

function App(props) {
  const [loginPreference, setLoginPreference] = useState(JSON.parse(sessionStorage.getItem('loginPreference')));

  React.useEffect(() => {
    sessionStorage.setItem('loginPreference', JSON.stringify(loginPreference));
  }, [loginPreference]);

  React.useEffect(() => {
    document.addEventListener('keydown', checkKey);
  }, []);

  function SetLoginOption(value) {
    var newLoginPreference = {
      useSSO: null
    };

    if (value === 'useSSO') {
      newLoginPreference.useSSO = true;
    }
    else {
      newLoginPreference.useSSO = false;
    }

    setLoginPreference(newLoginPreference);
  }

  function checkKey(event) {
    if (event.isComposing) {
      return;
    }

    if (event.key === ".") {
      setLoginPreference({});
    }
  }

  if (loginPreference === null) {
    return (<LoadLoginOptions SetLoginOption={SetLoginOption} />);
  }

  if (!props.authenticated) {
    if (loginPreference && loginPreference.useSSO) {
      return (<RedirectToLogin />);
    }
  }

  return (<LoadNormalPage {...props} />);
}

function RedirectToLogin() {
  const { instance } = useMsal();
  instance.loginRedirect();

  return (
    <>
      <img className="bgImage" src={background} alt="Illustration" />

      <div className="PageContainer">
        <LoadingLogin />
      </div>
    </>
  );
}

function LoadNormalPage(props) {
  return (
    <>
      <img className="bgImage" src={background} alt="Illustration" />

      <div className="PageContainer">
        <Header {...props} />
        <Body {...props} />
        <Footer {...props} />
      </div>
    </>
  );
}

function LoadLoginOptions(props) {
  return (
    <>
      <img className="bgImage" src={background} alt="Illustration" />

      <div className="PageContainer">
        <LoginOptions {...props} />
      </div>
    </>
  );
}

export default App;